<template>
  <div v-if="isLogin && ticket_count > 0 && hasUsableTicket" class="has_ticket">
    <a>
      <img
        src="@/assets/img/ticket/logo_coupon_small.png"
        class="logo_coupon"
        width="66"
        height="24"
      />
      <span>応援クーポンが{{ ticket_count }}枚利用可能</span>
    </a>
  </div>
</template>

<script>
import moment from 'moment';
import ticketApi from '@/modules/api/v2/tickets';
import ticketCalculatable from '@/mixins/payment/ticketCalculatable';
import Cookie from 'js-cookie';
import UA from '@/modules/UserAgent';

export default {
  name: 'HasTicket',
  mixins: [ticketCalculatable],
  props: {
    projectId: {
      type: String,
      required: true,
    },
    maxReturnPrice: {
      type: String,
      required: true,
    },
    tagIDs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      acquired_tickets: [],
      ticket_count: 0,
      isLogin: Cookie.get('is_logined') === 'true',
      hasUsableTicket: false,
      brazeCustomEventFired: false,
      ua: null,
    };
  },
  async created() {
    const ua = new UA();
    await ua.init();
    this.ua = ua;
  },
  mounted() {
    if (this.isLogin) {
      this.fetchTicketCount();
    }
  },
  methods: {
    async fetchTicketCount() {
      const { maxReturnPrice } = this;
      const self = this;
      const res = await ticketApi.fetchTicket();

      this.acquired_tickets = (res.data.acquired || [])
        .filter(ticket => self.isUsable(ticket, maxReturnPrice))
        .filter(ticket => self.isAvailableTargetProject(ticket))
        .filter(ticket =>
          moment().isSameOrAfter(moment.unix(ticket.can_use_date_from)),
        );
      this.ticket_count = this.acquired_tickets.length;
      this.hasUsableTicket = this.ticket_count > 0;

      if (!this.isNative()) {
        const hasAnyTicket =
          (res.data.acquired || []).length > 0 ||
          (res.data.not_acquired || []).length > 0;
        if (hasAnyTicket) {
          this.setUpBrazeCustomEventTrigger(
            res.data.acquired || [],
            res.data.not_acquired || [],
          );
        }
      }
    },
    isAvailableTargetProject(ticket) {
      const AVAILABLE_TARGET_TYPE_ALL = 1;
      const AVAILABLE_TARGET_TYPE_PROJECT_ID = 2;
      const AVAILABLE_TARGET_TYPE_TAG_ID = 5;
      if (ticket.available_target_types === AVAILABLE_TARGET_TYPE_ALL)
        return true;

      if (ticket.available_target_types === AVAILABLE_TARGET_TYPE_PROJECT_ID) {
        return ticket.projects.some(
          project => String(project.id) === this.projectId,
        );
      }

      if (ticket.available_target_types === AVAILABLE_TARGET_TYPE_TAG_ID) {
        return ticket.tags.some(tag => this.tagIDs.includes(String(tag.id)));
      }

      return false;
    },
    setUpBrazeCustomEventTrigger(acquiredTickets, notAcquiredTickets) {
      window.addEventListener('scroll', () => {
        if (this.brazeCustomEventFired) return;

        let status = null;
        let ticket = null;
        if (acquiredTickets.length > 0) {
          status = 'acquired';
          [ticket] = acquiredTickets.sort(
            (a, b) => a.can_use_date_to - b.can_use_date_to,
          );
        } else if (notAcquiredTickets.length > 0) {
          status = 'not_acquired';
          [ticket] = notAcquiredTickets;
        } else {
          return;
        }

        // 画面の70%のスクロール量でイベントを発火する
        const scrolledValue = window.pageYOffset;
        const threshold = window.innerHeight * 0.7;
        if (scrolledValue > threshold) {
          const { braze } = window;
          braze.logCustomEvent('browse_project_page_with_coupon', {
            ticket_id: ticket.id,
            can_use_date_to: ticket.can_use_date_to,
            status,
            project_id: this.projectId,
          });
          this.brazeCustomEventFired = true;
        }
      });
    },
    isNative() {
      return this.ua && this.ua.isNative();
    },
  },
};
</script>

<style scoped>
.has_ticket {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 28px;
  padding-top: 28px;
}

.has_ticket a {
  text-align: center;
}

.has_ticket a img {
  vertical-align: middle;
}

.has_ticket a span {
  color: #333;
  font-size: 13px;
  margin-left: 14px;
  vertical-align: middle;
}

.logo_coupon {
  height: 24px;
  width: 66px;
}
</style>
